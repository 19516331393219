import { withUserOnStaticPage } from 'app/hocs/withUserOnStaticPage';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { routes } from 'shared/common/routes';
import { Button } from 'shared/ui';
import styled from 'styled-components';
import { MainLayout } from 'widgets/layouts';

const Error404: FC = () => {
  const { t } = useTranslation('404.page');

  const head = {
    title: t('head.title'),
  };

  return (
    <MainLayout head={head} hasFooter>
      <Container>
        <StatusCode>404</StatusCode>
        <NotFoundText>{t('notFound')}</NotFoundText>
        <Link href={routes.home}>
          <a href={routes.home}>
            <Button>{t('buttonGoToHome')}</Button>
          </a>
        </Link>
      </Container>
    </MainLayout>
  );
};

export default withUserOnStaticPage(Error404);

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StatusCode = styled.p`
  font-size: 60px;
  font-weight: bold;
  color: var(--purple);
`;
const NotFoundText = styled.h1`
  font-size: 24px;
  margin-bottom: 40px;
`;
